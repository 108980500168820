var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-combobox", {
    attrs: {
      solo: _vm.solo,
      outlined: _vm.outlined,
      label: _vm.label,
      items: _vm.autocompleteList.list,
      "search-input": _vm.substring,
      loading: _vm.autocompleteList.isBusy || _vm.busy,
      "menu-props": _vm.menuProps,
      "prepend-inner-icon": _vm.icon,
      "return-object": "",
      filter: _vm.filter,
      "hide-details": "",
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.substring = $event
      },
      "update:search-input": [
        function ($event) {
          _vm.substring = $event
        },
        _vm.substringChanged,
      ],
    },
    scopedSlots: _vm._u([
      {
        key: "no-data",
        fn: function () {
          return [
            _c("v-list-item", [
              _c("span", { staticClass: "subheading mr-2" }, [
                _vm._v(_vm._s(_vm.noSuggestionText)),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.input,
      callback: function ($$v) {
        _vm.input = $$v
      },
      expression: "input",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }