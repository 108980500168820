var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column justify-end" },
    [
      _c(
        "Label",
        {
          attrs: {
            text: _vm.count === 1 ? _vm.titleSingular : _vm.titlePlural,
            location: "bottom",
            align: "center",
            justify: "end",
          },
        },
        [
          _c(
            "span",
            {
              class: {
                "font-weight-black text-center number": true,
                "color-ok":
                  _vm.criticalNumber === undefined ||
                  _vm.count < _vm.criticalNumber,
                "color-critical":
                  _vm.criticalNumber !== undefined &&
                  _vm.count >= _vm.criticalNumber,
              },
            },
            [_vm._v(_vm._s(_vm.count))]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }