var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        "min-width": _vm.minWidth,
        "min-height": _vm.minHeight,
        "max-height": _vm.maxHeight,
        "max-width": _vm.maxWidth,
        flat: "",
      },
    },
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-list-item",
                    { staticClass: "ma-auto" },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "mr-2" },
                        [_c("v-icon", [_vm._v("fa-light fa-hourglass-half")])],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v(" " + _vm._s(_vm.label) + " "),
                      ]),
                      _c("v-progress-linear", {
                        attrs: { indeterminate: "", bottom: "", absolute: "" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }