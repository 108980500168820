var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { dragging: _vm.dragging },
      on: {
        drop: function ($event) {
          $event.preventDefault()
          return _vm.drop.apply(null, arguments)
        },
        dragover: function ($event) {
          $event.preventDefault()
          _vm.dragging = true
        },
        dragleave: function ($event) {
          $event.preventDefault()
          _vm.dragging = false
        },
      },
    },
    [
      _c("v-file-input", {
        class: { "v-input--is-focused": _vm.dragging },
        attrs: {
          outlined: "",
          disabled: _vm.disabled,
          label: _vm.title,
          "prepend-icon": null,
          "hide-details": "",
          accept: "image/*",
          "prepend-inner-icon": _vm.icon,
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function (ref) {
              var file = ref.file
              return [
                _c("v-img", {
                  staticClass: "ma-5",
                  attrs: {
                    "aspect-ratio": "1",
                    src: _vm.getUrl(file),
                    "max-height": _vm.maxPreviewHeight,
                    "max-width": _vm.maxPreviewWidth,
                    contain: true,
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.image,
          callback: function ($$v) {
            _vm.image = $$v
          },
          expression: "image",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }