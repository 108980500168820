var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-dialog", {
    attrs: { scrollable: "" },
    scopedSlots: _vm._u(
      [
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [_vm._t("activator", null, null, { on: on, attrs: attrs })]
          },
        },
        {
          key: "default",
          fn: function () {
            return [
              _c(
                "v-card",
                { staticClass: "mx-auto" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(_vm._s(_vm.$t("niceClassFilterPopup.title"))),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("fa-light fa-xmark")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pt-6 pb-0" },
                    [
                      _c(
                        "v-form",
                        { ref: "selectionForm", attrs: { light: "" } },
                        [
                          _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.items,
                                          dense: "",
                                          chips: "",
                                          "small-chips": "",
                                          label: _vm.$t(
                                            "niceClassFilterPopup.selectorLabel"
                                          ),
                                          multiple: "",
                                          "return-object": "",
                                          locale: _vm.$i18n.locale,
                                          filter: _vm.filterNiceClasses,
                                          "no-data-text": _vm.$t(
                                            "niceClassFilterPopup.noMatch"
                                          ),
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "selection",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "v-chip",
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "black--text ma-1",
                                                      attrs: {
                                                        "input-value":
                                                          data.selected,
                                                        close: "",
                                                      },
                                                      on: {
                                                        click: data.select,
                                                        "click:close":
                                                          function ($event) {
                                                            return _vm.remove(
                                                              data.item
                                                            )
                                                          },
                                                      },
                                                    },
                                                    "v-chip",
                                                    data.attrs,
                                                    false
                                                  ),
                                                  [
                                                    data.item.icon
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              left: "",
                                                              small: "",
                                                              color:
                                                                data.item.color,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.item.icon
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.item.title
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item",
                                            fn: function (data) {
                                              return [
                                                typeof data.item !== "object"
                                                  ? [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _vm._v(
                                                            _vm._s(data.item)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : [
                                                      data.item.icon
                                                        ? _c(
                                                            "v-list-item-icon",
                                                            {
                                                              staticClass:
                                                                "mr-3",
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      data.item
                                                                        .color,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      data.item
                                                                        .icon
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    data.item
                                                                      .title
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-list-item-subtitle",
                                                            {
                                                              staticClass:
                                                                "wrap-text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    data.item
                                                                      .toolTip
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                              ]
                                            },
                                          },
                                        ]),
                                        model: {
                                          value: _vm.selectedInternal,
                                          callback: function ($$v) {
                                            _vm.selectedInternal = $$v
                                          },
                                          expression: "selectedInternal",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("niceClassFilterPopup.close")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v
      },
      expression: "visible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }