var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    { staticClass: "bg-light" },
    [
      _c(
        "section",
        { staticClass: "pt-16", attrs: { id: "search-input" } },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { xl: "5", lg: "7" } },
                    [
                      _c(
                        "v-scroll-y-transition",
                        {
                          staticClass: "d-flex flex-column",
                          attrs: { group: "", "leave-absolute": "" },
                        },
                        [
                          _c(
                            "v-chip-group",
                            {
                              key: "searchInputMode",
                              staticClass: "my-3 align-self-center",
                              attrs: {
                                mandatory: "",
                                "active-class": "primary primary--text",
                                "show-arrows": "",
                              },
                              model: {
                                value: _vm.searchMode,
                                callback: function ($$v) {
                                  _vm.searchMode = $$v
                                },
                                expression: "searchMode",
                              },
                            },
                            [
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    label: "",
                                    outlined: "",
                                    value: "Name",
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("fa-light fa-input-text"),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("brandSearch.searchModes.name")
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-badge",
                                {
                                  staticStyle: { "z-index": "2" },
                                  attrs: {
                                    content: "Pre",
                                    color: "green darken-3",
                                    overlap: "",
                                    "offset-x": "25",
                                    "offset-y": "16",
                                  },
                                },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        label: "",
                                        outlined: "",
                                        value: "Logo",
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("fa-light fa-image"),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "brandSearch.searchModes.logo"
                                            )
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-badge",
                                {
                                  staticStyle: { "z-index": "1" },
                                  attrs: {
                                    content: "Pre",
                                    color: "green darken-3",
                                    overlap: "",
                                    "offset-x": "25",
                                    "offset-y": "16",
                                  },
                                },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        label: "",
                                        outlined: "",
                                        value: "NameAndLogo",
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("$combined-mark"),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "brandSearch.searchModes.nameAndLogo"
                                            )
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    label: "",
                                    outlined: "",
                                    value: "Owner",
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("fa-light fa-buildings"),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("brandSearch.searchModes.owner")
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    label: "",
                                    outlined: "",
                                    value: "RegistrationNumber",
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("fa-light fa-input-numeric"),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "brandSearch.searchModes.registrationNumber"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.searchMode === "Name" ||
                          _vm.searchMode === "NameAndLogo"
                            ? _c("BrandNameCombobox", {
                                key: "searchInputBrandName",
                                staticClass: "my-3",
                                attrs: {
                                  outlined: "",
                                  label: _vm.$t("brandNameCombobox.label"),
                                  icon: "fa-light fa-magnifying-glass",
                                  noSuggestionText: _vm.$t(
                                    "brandNameCombobox.noSuggestion"
                                  ),
                                },
                                on: { inputChanged: _vm.executeNewSearch },
                                model: {
                                  value: _vm.searchInputBrandName,
                                  callback: function ($$v) {
                                    _vm.searchInputBrandName = $$v
                                  },
                                  expression: "searchInputBrandName",
                                },
                              })
                            : _vm._e(),
                          _vm.searchMode === "Logo" ||
                          _vm.searchMode === "NameAndLogo"
                            ? _c("ImagePicker", {
                                key: "searchInputLogo",
                                staticClass: "my-3",
                                attrs: {
                                  title: _vm.$t("brandSearch.logoPickerTitle"),
                                  icon: "fa-light fa-image",
                                  "max-preview-height": "300",
                                },
                                model: {
                                  value: _vm.logo,
                                  callback: function ($$v) {
                                    _vm.logo = $$v
                                  },
                                  expression: "logo",
                                },
                              })
                            : _vm._e(),
                          _vm.searchMode === "RegistrationNumber"
                            ? _c("SearchTextField", {
                                key: "searchInputRegistrationNumber",
                                staticClass: "my-3",
                                attrs: {
                                  label: _vm.$t(
                                    "registrationNumberTextField.label"
                                  ),
                                  outlined: "",
                                  icon: "fa-light fa-magnifying-glass",
                                },
                                on: { confirmed: _vm.executeNewSearch },
                                model: {
                                  value: _vm.searchInputRegistrationNumber,
                                  callback: function ($$v) {
                                    _vm.searchInputRegistrationNumber = $$v
                                  },
                                  expression: "searchInputRegistrationNumber",
                                },
                              })
                            : _vm._e(),
                          _vm.searchMode === "Owner"
                            ? _c("SearchTextField", {
                                key: "searchInputOwner",
                                staticClass: "my-3",
                                attrs: {
                                  label: _vm.$t("ownerTextField.label"),
                                  outlined: "",
                                  icon: "fa-light fa-magnifying-glass",
                                },
                                on: { confirmed: _vm.executeNewSearch },
                                model: {
                                  value: _vm.searchInputOwner,
                                  callback: function ($$v) {
                                    _vm.searchInputOwner = $$v
                                  },
                                  expression: "searchInputOwner",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              key: "searchInputExecute",
                              staticClass: "my-3 align-self-center",
                              attrs: {
                                dark: _vm.searchInputComplete,
                                color: "tertiary",
                                width: "200",
                                disabled: !_vm.searchInputComplete,
                                loading: _vm.brandQueryResult.isBusy,
                              },
                              on: { click: _vm.executeNewSearch },
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("fa-light fa-play"),
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("brandSearch.searchButton"))
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "section",
        {
          staticClass: "pt-16",
          style: { display: _vm.brandQueryResult.object ? "inherit" : "none" },
          attrs: { id: "search-results" },
        },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "d-flex flex-row" },
                    [
                      _vm.$vuetify.breakpoint.mdAndUp && _vm.showFilters
                        ? _c(
                            "v-card",
                            {
                              staticClass: "pr-6",
                              attrs: {
                                flat: "",
                                tile: "",
                                color: "transparent",
                              },
                            },
                            [
                              _c(
                                "v-toolbar",
                                {
                                  staticClass: "px-0",
                                  attrs: {
                                    dense: "",
                                    flat: "",
                                    color: "transparent",
                                  },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showFilters = !_vm.showFilters
                                        },
                                      },
                                      model: {
                                        value: _vm.showFilters,
                                        callback: function ($$v) {
                                          _vm.showFilters = $$v
                                        },
                                        expression: "showFilters",
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("fa-light fa-bars-filter"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.brandQueryResult.object
                                ? _c("BrandFilterTreeView", {
                                    ref: "filterTreeView",
                                    attrs: {
                                      facets:
                                        _vm.brandQueryResult.object.facets,
                                    },
                                    on: { applyFilters: _vm.applyFilters },
                                    model: {
                                      value: _vm.selectedFilters,
                                      callback: function ($$v) {
                                        _vm.selectedFilters = $$v
                                      },
                                      expression: "selectedFilters",
                                    },
                                  })
                                : _c("BrandFilterTreeView", {
                                    ref: "filterTreeView",
                                    on: { applyFilters: _vm.applyFilters },
                                    model: {
                                      value: _vm.selectedFilters,
                                      callback: function ($$v) {
                                        _vm.selectedFilters = $$v
                                      },
                                      expression: "selectedFilters",
                                    },
                                  }),
                            ],
                            1
                          )
                        : _vm.$vuetify.breakpoint.smAndDown
                        ? _c(
                            "v-navigation-drawer",
                            {
                              attrs: { dark: "", fixed: "", width: "auto" },
                              model: {
                                value: _vm.showFilters,
                                callback: function ($$v) {
                                  _vm.showFilters = $$v
                                },
                                expression: "showFilters",
                              },
                            },
                            [
                              _c(
                                "v-list-item",
                                { staticClass: "pt-2" },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          _vm.showFilters = !_vm.showFilters
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("fa-light fa-chevron-left"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.brandQueryResult.object
                                ? _c("BrandFilterTreeView", {
                                    ref: "filterTreeView",
                                    attrs: {
                                      facets:
                                        _vm.brandQueryResult.object.facets,
                                    },
                                    on: { applyFilters: _vm.applyFilters },
                                    model: {
                                      value: _vm.selectedFilters,
                                      callback: function ($$v) {
                                        _vm.selectedFilters = $$v
                                      },
                                      expression: "selectedFilters",
                                    },
                                  })
                                : _c("BrandFilterTreeView", {
                                    ref: "filterTreeView",
                                    on: { applyFilters: _vm.applyFilters },
                                    model: {
                                      value: _vm.selectedFilters,
                                      callback: function ($$v) {
                                        _vm.selectedFilters = $$v
                                      },
                                      expression: "selectedFilters",
                                    },
                                  }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-card",
                        {
                          staticClass: "d-flex flex-column justify-start",
                          attrs: {
                            flat: "",
                            tile: "",
                            color: "transparent",
                            width: "100%",
                          },
                        },
                        [
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "px-0",
                              attrs: {
                                dense: "",
                                light: "",
                                flat: "",
                                "max-height": "48",
                              },
                            },
                            [
                              !_vm.showFilters
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showFilters = !_vm.showFilters
                                        },
                                      },
                                      model: {
                                        value: _vm.showFilters,
                                        callback: function ($$v) {
                                          _vm.showFilters = $$v
                                        },
                                        expression: "showFilters",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "tertiary" } },
                                        [_vm._v("fa-light fa-bars-filter")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$vuetify.breakpoint.mdAndDown
                                ? _c("v-spacer")
                                : _vm._e(),
                              _vm.brandQueryResult.object &&
                              _vm.brandQueryResult.object.items &&
                              _vm.brandQueryResult.object.items.length > 0
                                ? [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { text: "" },
                                        on: { click: _vm.scrollToPaging },
                                      },
                                      [
                                        _vm.$vuetify.breakpoint.smAndUp
                                          ? _c(
                                              "span",
                                              { staticClass: "mr-1" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("brandSearch.page")
                                                  ) + ":"
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              { staticClass: "mr-1" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "brandSearch.pageMobile"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-black" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.currentPage) +
                                                " / " +
                                                _vm._s(_vm.numberOfPages)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm.numberOfPages && _vm.numberOfPages > 0
                                      ? _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              rounded: "0",
                                              "offset-y": "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                text: "",
                                                              },
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm.$vuetify
                                                            .breakpoint.smAndUp
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "brandSearch.resultsPerPage"
                                                                      )
                                                                    ) + ":"
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "brandSearch.resultsPerPageMobile"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-black",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.pageSize
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              463542444
                                            ),
                                          },
                                          [
                                            _c(
                                              "v-list",
                                              { staticClass: "pa-0" },
                                              [
                                                _c(
                                                  "v-list-item-group",
                                                  {
                                                    attrs: { mandatory: "" },
                                                    model: {
                                                      value: _vm.pageSize,
                                                      callback: function ($$v) {
                                                        _vm.pageSize = $$v
                                                      },
                                                      expression: "pageSize",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.pageSizes,
                                                    function (item) {
                                                      return _c(
                                                        "v-list-item",
                                                        {
                                                          key: item,
                                                          attrs: {
                                                            value: item,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-content",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.toString()
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                              _vm.$vuetify.breakpoint.lgAndUp
                                ? _c("v-spacer")
                                : _vm._e(),
                              _vm.$vuetify.breakpoint.lgAndUp
                                ? _c(
                                    "v-btn-toggle",
                                    {
                                      attrs: { dense: "", group: "" },
                                      model: {
                                        value: _vm.activeQueryResultView,
                                        callback: function ($$v) {
                                          _vm.activeQueryResultView = $$v
                                        },
                                        expression: "activeQueryResultView",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            value: "vertical-view",
                                            text: "",
                                            disabled:
                                              !_vm.brandQueryResult.object ||
                                              !_vm.brandQueryResult.object
                                                .items ||
                                              _vm.brandQueryResult.object.items
                                                .length === 0,
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              "fa-light fa-distribute-spacing-horizontal"
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            value: "horizontal-view",
                                            text: "",
                                            disabled:
                                              !_vm.brandQueryResult.object ||
                                              !_vm.brandQueryResult.object
                                                .items ||
                                              _vm.brandQueryResult.object.items
                                                .length === 0,
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              "fa-light fa-distribute-spacing-vertical"
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "v-container",
                            { staticClass: "pa-0 mt-4", attrs: { fluid: "" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-chip-group",
                                        {
                                          attrs: {
                                            "active-class": "primary--text",
                                            column: "",
                                          },
                                        },
                                        _vm._l(
                                          _vm.selectedFilters,
                                          function (filter, index) {
                                            return _c(
                                              "v-chip",
                                              {
                                                key: "filter_" + index,
                                                attrs: { label: "", close: "" },
                                                on: {
                                                  "click:close": function (
                                                    $event
                                                  ) {
                                                    return _vm.removeSelectedFilter(
                                                      filter
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(filter.title) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.brandQueryResult.object &&
                          _vm.brandQueryResult.object.items &&
                          _vm.brandQueryResult.object.items.length > 0
                            ? _c(
                                "div",
                                { staticClass: "pb-2" },
                                [
                                  _vm.displayInfoSimilarBrandsFound &&
                                  _vm.userAccount
                                    ? _c(
                                        "v-alert",
                                        {
                                          staticClass: "mt-2 mb-4",
                                          attrs: {
                                            icon: "fa-solid fa-circle-info",
                                            text: "",
                                            type: "info",
                                            color: "orange lighten-1",
                                          },
                                        },
                                        [
                                          _c("i18n", {
                                            attrs: {
                                              path: "brandSearch.infoSimilarBrandsFound",
                                              tag: "div",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "input",
                                                  fn: function () {
                                                    return [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.searchInputBrandName
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              2739463494
                                            ),
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm.displayInfoSimilarBrandsFound
                                    ? _c(
                                        "v-alert",
                                        {
                                          staticClass: "mt-2 mb-4",
                                          attrs: {
                                            icon: "fa-solid fa-circle-info",
                                            text: "",
                                            type: "info",
                                            color: "orange lighten-1",
                                          },
                                        },
                                        [
                                          _c("i18n", {
                                            attrs: {
                                              path: "brandSearch.infoSimilarBrandsFoundNotAuthenticated",
                                              tag: "div",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "input",
                                                  fn: function () {
                                                    return [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.searchInputBrandName
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "login",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "tertiary--text text-decoration-underline",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.$auth.signIn()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "brandSearch.infoSimilarBrandsFoundLogin"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              1040261969
                                            ),
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.activeQueryResultView ||
                                  _vm.activeQueryResultView === "vertical-view"
                                    ? _c("VerticalBrandTileGrid", {
                                        attrs: {
                                          brands:
                                            _vm.brandQueryResult.object.items,
                                          infoMode: "Maximum",
                                        },
                                      })
                                    : _vm.activeQueryResultView ===
                                      "horizontal-view"
                                    ? _c("HorizontalBrandTileGrid", {
                                        attrs: {
                                          brands:
                                            _vm.brandQueryResult.object.items,
                                        },
                                      })
                                    : _vm.activeQueryResultView === "table-view"
                                    ? _c("BrandTable", {
                                        attrs: {
                                          brands:
                                            _vm.brandQueryResult.object.items,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "fill-height",
                                  staticStyle: { "padding-top": "100px" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-center",
                                      staticStyle: {
                                        position: "sticky",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: {
                                            "margin-right": "16px",
                                            "font-weight": "900",
                                          },
                                          attrs: { color: "tertiary" },
                                        },
                                        [_vm._v("fa-light fa-face-thinking")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "tertiary--text",
                                          staticStyle: { "font-size": "16px" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("brandSearch.noBrandFound")
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                          _vm.brandQueryResult.object &&
                          _vm.brandQueryResult.object.items &&
                          _vm.brandQueryResult.object.items.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "py-16",
                                  attrs: { id: "pagination" },
                                },
                                [
                                  _c("v-pagination", {
                                    attrs: {
                                      length: _vm.numberOfPages,
                                      "total-visible": 6,
                                    },
                                    on: { input: _vm.changePage },
                                    model: {
                                      value: _vm.currentPage,
                                      callback: function ($$v) {
                                        _vm.currentPage = $$v
                                      },
                                      expression: "currentPage",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-speed-dial",
                            {
                              attrs: {
                                direction: "top",
                                fixed: "",
                                bottom: "",
                                right: "",
                                "open-on-hover": "",
                                transition: "slide-y-reverse-transition",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            fab: "",
                                            medium: "",
                                            color: "tertiary",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { dark: "" } },
                                            [
                                              _vm._v(
                                                "fa-light fa-ellipsis-stroke-vertical"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { left: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    fab: "",
                                                    medium: "",
                                                    color: "tertiary",
                                                    disabled:
                                                      !_vm.userAccount ||
                                                      !_vm.currentBrandQuery,
                                                  },
                                                  on: {
                                                    click: _vm.openSaveNewQuery,
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { dark: "" } },
                                                [
                                                  _vm._v(
                                                    "fa-light fa-floppy-disk"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [_c("span", [_vm._v("Save this query")])]
                              ),
                            ],
                            1
                          ),
                          _vm.newStoredBrandQuery
                            ? _c("ConfigureStoredBrandQueryPopup", {
                                attrs: {
                                  storedBrandQuery: _vm.newStoredBrandQuery,
                                },
                                model: {
                                  value: _vm.saveNewQueryVisible,
                                  callback: function ($$v) {
                                    _vm.saveNewQueryVisible = $$v
                                  },
                                  expression: "saveNewQueryVisible",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-progress-linear", {
        attrs: {
          indeterminate: "",
          fixed: "",
          bottom: "",
          active: _vm.brandQueryResult.isBusy,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }