var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-dialog", {
    attrs: { scrollable: "", "max-width": "800" },
    scopedSlots: _vm._u(
      [
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [_vm._t("activator", null, null, { on: on, attrs: attrs })]
          },
        },
        {
          key: "default",
          fn: function () {
            return [
              _c(
                "v-card",
                { staticClass: "mx-auto" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(
                          _vm._s(_vm.$t("configureBrandFilterPopup.title"))
                        ),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("fa-light fa-xmark")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pt-6 pb-0" },
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: { "background-color": "transparent" },
                          model: {
                            value: _vm.selectedTab,
                            callback: function ($$v) {
                              _vm.selectedTab = $$v
                            },
                            expression: "selectedTab",
                          },
                        },
                        [
                          _c("v-tab", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "configureBrandFilterPopup.tabApplication"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("v-tab", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "configureBrandFilterPopup.tabRegistration"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("v-tab", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "configureBrandFilterPopup.tabOppositionPeriodEnd"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-tabs-items",
                        {
                          staticClass: "my-5",
                          model: {
                            value: _vm.selectedTab,
                            callback: function ($$v) {
                              _vm.selectedTab = $$v
                            },
                            expression: "selectedTab",
                          },
                        },
                        [
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "applicationDateFilterForm",
                                  attrs: { light: "" },
                                  model: {
                                    value: _vm.applicationDateFilterFormValid,
                                    callback: function ($$v) {
                                      _vm.applicationDateFilterFormValid = $$v
                                    },
                                    expression:
                                      "applicationDateFilterFormValid",
                                  },
                                },
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { fluid: "" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex flex-row justify-center",
                                              attrs: { xs: "12", md: "6" },
                                            },
                                            [
                                              _c("v-date-picker", {
                                                attrs: {
                                                  "selected-items-text": _vm.$t(
                                                    "configureBrandFilterPopup.daysPicked",
                                                    {
                                                      days: _vm.countDays(
                                                        _vm.applicationDateFrom,
                                                        _vm.applicationDateUntil
                                                      ),
                                                    }
                                                  ),
                                                  range: "",
                                                  locale: _vm.$i18n.locale,
                                                  color: "primary",
                                                },
                                                model: {
                                                  value:
                                                    _vm.selectedApplicationDates,
                                                  callback: function ($$v) {
                                                    _vm.selectedApplicationDates =
                                                      $$v
                                                  },
                                                  expression:
                                                    "selectedApplicationDates",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { xs: "12", md: "6" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "configureBrandFilterPopup.from"
                                                  ),
                                                  "prepend-icon":
                                                    "fa-light fa-calendar-arrow-down",
                                                  required: "",
                                                  rules: [
                                                    _vm.$validation.required,
                                                  ],
                                                  readonly: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.applicationDateFromLocalized,
                                                  callback: function ($$v) {
                                                    _vm.applicationDateFromLocalized =
                                                      $$v
                                                  },
                                                  expression:
                                                    "applicationDateFromLocalized",
                                                },
                                              }),
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "configureBrandFilterPopup.until"
                                                  ),
                                                  "prepend-icon":
                                                    "fa-light fa-calendar-arrow-up",
                                                  readonly: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.applicationDateUntilLocalized,
                                                  callback: function ($$v) {
                                                    _vm.applicationDateUntilLocalized =
                                                      $$v
                                                  },
                                                  expression:
                                                    "applicationDateUntilLocalized",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row justify-center",
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "ma-1",
                                                      attrs: {
                                                        disabled:
                                                          !_vm.applicationDateFilterFormValid,
                                                        plain: "",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.addApplicationDateFilter,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [
                                                          _vm._v(
                                                            "fa-light fa-plus"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "configureBrandFilterPopup.addFilter"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "registrationDateFilterForm",
                                  attrs: { light: "" },
                                  model: {
                                    value: _vm.registrationDateFilterFormValid,
                                    callback: function ($$v) {
                                      _vm.registrationDateFilterFormValid = $$v
                                    },
                                    expression:
                                      "registrationDateFilterFormValid",
                                  },
                                },
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { fluid: "" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex flex-row justify-center",
                                              attrs: { xs: "12", md: "6" },
                                            },
                                            [
                                              _c("v-date-picker", {
                                                attrs: {
                                                  "selected-items-text": _vm.$t(
                                                    "configureBrandFilterPopup.daysPicked",
                                                    {
                                                      days: _vm.countDays(
                                                        _vm.registrationDateFrom,
                                                        _vm.registrationDateUntil
                                                      ),
                                                    }
                                                  ),
                                                  range: "",
                                                  locale: _vm.$i18n.locale,
                                                  color: "primary",
                                                },
                                                model: {
                                                  value:
                                                    _vm.selectedRegistrationDates,
                                                  callback: function ($$v) {
                                                    _vm.selectedRegistrationDates =
                                                      $$v
                                                  },
                                                  expression:
                                                    "selectedRegistrationDates",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { xs: "12", md: "6" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "configureBrandFilterPopup.from"
                                                  ),
                                                  "prepend-icon":
                                                    "fa-light fa-calendar-arrow-down",
                                                  required: "",
                                                  rules: [
                                                    _vm.$validation.required,
                                                  ],
                                                  readonly: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.registrationDateFromLocalized,
                                                  callback: function ($$v) {
                                                    _vm.registrationDateFromLocalized =
                                                      $$v
                                                  },
                                                  expression:
                                                    "registrationDateFromLocalized",
                                                },
                                              }),
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "configureBrandFilterPopup.until"
                                                  ),
                                                  "prepend-icon":
                                                    "fa-light fa-calendar-arrow-up",
                                                  readonly: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.registrationDateUntilLocalized,
                                                  callback: function ($$v) {
                                                    _vm.registrationDateUntilLocalized =
                                                      $$v
                                                  },
                                                  expression:
                                                    "registrationDateUntilLocalized",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row justify-center",
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "ma-1",
                                                      attrs: {
                                                        disabled:
                                                          !_vm.registrationDateFilterFormValid,
                                                        plain: "",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.addRegistrationDateFilter,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [
                                                          _vm._v(
                                                            "fa-light fa-plus"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "configureBrandFilterPopup.addFilter"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "oppositionPeriodEndDateFilterForm",
                                  attrs: { light: "" },
                                  model: {
                                    value:
                                      _vm.oppositionPeriodEndDateFilterFormValid,
                                    callback: function ($$v) {
                                      _vm.oppositionPeriodEndDateFilterFormValid =
                                        $$v
                                    },
                                    expression:
                                      "oppositionPeriodEndDateFilterFormValid",
                                  },
                                },
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { fluid: "" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex flex-row justify-center",
                                              attrs: { xs: "12", md: "6" },
                                            },
                                            [
                                              _c("v-date-picker", {
                                                attrs: {
                                                  "selected-items-text": _vm.$t(
                                                    "configureBrandFilterPopup.daysPicked",
                                                    {
                                                      days: _vm.countDays(
                                                        _vm.oppositionPeriodEndDateFrom,
                                                        _vm.oppositionPeriodEndDateUntil
                                                      ),
                                                    }
                                                  ),
                                                  range: "",
                                                  locale: _vm.$i18n.locale,
                                                  color: "primary",
                                                },
                                                model: {
                                                  value:
                                                    _vm.selectedOppositionPeriodEndDates,
                                                  callback: function ($$v) {
                                                    _vm.selectedOppositionPeriodEndDates =
                                                      $$v
                                                  },
                                                  expression:
                                                    "selectedOppositionPeriodEndDates",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { xs: "12", md: "6" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "configureBrandFilterPopup.from"
                                                  ),
                                                  "prepend-icon":
                                                    "fa-light fa-calendar-arrow-down",
                                                  required: "",
                                                  rules: [
                                                    _vm.$validation.required,
                                                  ],
                                                  readonly: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.oppositionPeriodEndDateFromLocalized,
                                                  callback: function ($$v) {
                                                    _vm.oppositionPeriodEndDateFromLocalized =
                                                      $$v
                                                  },
                                                  expression:
                                                    "oppositionPeriodEndDateFromLocalized",
                                                },
                                              }),
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "configureBrandFilterPopup.until"
                                                  ),
                                                  "prepend-icon":
                                                    "fa-light fa-calendar-arrow-up",
                                                  readonly: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.oppositionPeriodEndDateUntilLocalized,
                                                  callback: function ($$v) {
                                                    _vm.oppositionPeriodEndDateUntilLocalized =
                                                      $$v
                                                  },
                                                  expression:
                                                    "oppositionPeriodEndDateUntilLocalized",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row justify-center",
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "ma-1",
                                                      attrs: {
                                                        disabled:
                                                          !_vm.oppositionPeriodEndDateFilterFormValid,
                                                        plain: "",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.addOppositionPeriodEndDateFilter,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [
                                                          _vm._v(
                                                            "fa-light fa-plus"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "configureBrandFilterPopup.addFilter"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("configureBrandFilterPopup.close"))
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v
      },
      expression: "visible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }